import React from 'react';
import { graphql } from 'gatsby';
import QemotionLayout from 'components/pages/QemotionPage';

const seo = {
  title: 'Q°emotion – Meet our team and learn more about Q°emotion!',
  keywords: [
    'Q°emotion',
    'Founder',
    'Team',
    'Emotional analysis tool',
    'Contact',
    'Address',
    'Recruitment',
    'Hiring',
  ],
  description:
    "They have created an AI that can analyze your clients' emotions.\n" +
    'Who are they? Why this project? Discover the story of Q°emotion.',
};

export default props => <QemotionLayout {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockIntroTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "qemotion" }
        blockType: { eq: "introBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockIntro: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "qemotion" }
          lang: { eq: "en" }
          blockType: { eq: "introBlock" }
          component: { eq: "staff" }
        }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          role
          name
          order
          socialNetwork {
            twitter
            linkedin
          }
        }
      }
    }
    imageIntroLeftBlock: allFile(
      filter: {
        relativeDirectory: { eq: "team/intro" }
        base: { in: ["1_left.png", "2_left.png", "3_left.png", "4_left.png"] }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imageIntroRightBlock: allFile(
      filter: {
        relativeDirectory: { eq: "team/intro" }
        base: {
          in: ["2_right.png", "3_right.png", "4_right.png", "5_right.png"]
        }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imageTeamBlock: allFile(
      filter: { relativeDirectory: { eq: "team" } }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    dataBlockTeam: markdownRemark(
      frontmatter: {
        pageType: { eq: "qemotion" }
        lang: { eq: "en" }
        blockType: { eq: "teamBlock" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        color
        link
        title
      }
    }
    dataBlockDescription: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "qemotion" }
          lang: { eq: "en" }
          blockType: { eq: "descriptionBlock" }
        }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          title
          subTitle
          component
        }
      }
    }
    dataBlockContact: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "qemotion" }
          lang: { eq: "en" }
          blockType: { eq: "contactBlock" }
          component: { eq: "address" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          title
        }
      }
    }
    dataContactUsBlockContact: markdownRemark(
      frontmatter: {
        pageType: { eq: "qemotion" }
        blockType: { eq: "contactBlock" }
        lang: { eq: "en" }
        component: { eq: "contact" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        title
      }
    }
    dataBlockBlog: markdownRemark(
      frontmatter: {
        pageType: { eq: "qemotion" }
        blockType: { eq: "blogBlock" }
        lang: { eq: "en" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        title
        color
        link
      }
    }
  }
`;
